<template>
  <footer id="footer">
    <a href="#" class="info fa fa-info-circle"><span>About</span></a>
    <div class="inner">
      <div class="content">
        <p><img id="shutterwax" src="images/shutterwax.png" alt="Shutterwax"></p>
        <!-- <a href="https://blocsonic.com" target="_blank">
          <img id="blocsonic" src="images/blocsonic.png" alt="BlocSonic Records">
        </a> -->
      </div>
      <div class="copyright">
        <h3>Follow us</h3>
        <ul class="icons">
          <li>
            <a href="https://shutterwax.bandcamp.com/follow_me" target="_blank" class="icon png">
              <img src="images/bandcamp-button-circle-aqua-32.png" alt="Follow us on bandcamp">
              <span class="label">Follow us on Bandcamp</span>
            </a>
          </li>
          <li><a href="https://twitter.com/shutterwax" target="_blank" class="icon fa-twitter">
            <span class="label">Twitter</span>
          </a></li>
          <li><a href="https://youtube.com/user/shutterwax" target="_blank" class="icon fa-youtube">
            <span class="label">YouTube</span>
          </a></li>
        </ul>
        &copy; Demantelez Music LLC and Shutterwax. All rights reserved.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>

.png img {
  margin-bottom: -6px;
}

#blocsonic {
  margin-top: 1rem;
  max-height: 8rem;
}

#shutterwax {
  max-width: 90%;
}
</style>
