<template>
  <div id="main">
    <div class="inner">
      <div class="columns">

        <!-- Column 1 (horizontal, vertical, horizontal, vertical) -->

        <div class="image fit">
          <router-link :to="{ name: 'PoliceState', params: {} }"><img src="images/police-state.jpg" alt="Police State by Shutterwax" /></router-link>
        </div>
        <div class="image fit">
          <router-link :to="{ name: 'Jeff', params: {} }"><img src="images/jeff.jpg" alt="Jeff Brayne" /></router-link>
        </div>
        <div class="image fit">
          <router-link :to="{ name: 'BigNews', params: {} }"><img src="images/big-news.jpg" alt="Big News by Shutterwax" /></router-link>
        </div>

        <!-- Column 2 (vertical, horizontal, vertical, horizontal) -->

        <div class="image fit">
          <router-link :to="{ name: 'Jessica', params: {} }"><img src="images/jess.jpg" alt="Jessica Brayne" /></router-link>
        </div>
        <div class="image fit">
          <router-link :to="{ name: 'Breathing', params: {} }"><img src="images/breathing.jpg" alt="Nothing Left but Breathing by Shutterwax" /></router-link>
        </div>
        <div class="image fit">
          <img src="images/sabine.jpg" alt="Nothing Left but Breathing by Shutterwax" />
        </div>
        <div class="image fit">
          <router-link :to="{ name: 'Raymond', params: {} }"><img src="images/raymond.jpg" alt="Raymond, Take Care of Eleanor by Shutterwax" /></router-link>
        </div>
        <div class="image fit">
          <router-link :to="{ name: 'Mark', params: {} }"><img src="images/mark.jpg" alt="Mark Rufino" /></router-link>
        </div>
        <div class="image fit">
          <router-link :to="{ name: 'Apocalypse', params: {} }"><img src="images/dig-me-a-well.jpg" alt="Apocalypse, Please Be True by Shutterwax" /></router-link>
        </div>

        <!-- Column 3 (horizontal, vertical, horizontal, vertical) -->


        <div class="image fit">
          <router-link :to="{ name: 'Time', params: {} }"><img src="images/time.jpg" alt="Out of Time by Shutterwax" /></router-link>
        </div>

        <div class="image fit">
          <router-link :to="{ name: 'Frank', params: {} }"><img src="images/frank.jpg" alt="Frank Diorio" /></router-link>
        </div>
        <div class="image fit">
          <router-link :to="{ name: 'Hurricane', params: {} }"><img src="images/hurricane.jpg" alt="Hurricane by Shutterwax" /></router-link>
        </div>
        <div class="image fit">
          <img src="images/jessica.jpg" alt="Hurricane by Shutterwax" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  components: {

  },
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
