<template>
  <div id="app">
    <header-component/>
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
    <footer-component/>
  </div>
</template>

<script>
/* eslint-disable */
import Header from '@/components/partials/Header.vue'
import HomePage from '@/components/HomePage.vue'
import Footer from '@/components/partials/Footer.vue'

export default {
  name: 'app',
  components: {
    'home-page': HomePage,
    'header-component': Header,
    'footer-component': Footer
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
