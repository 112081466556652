
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  base: '/',
  // mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/components/HomePage.vue'),
      meta: {
        reuse: true
      }
    },
    {
      path: '*',
      name: 'home',
      component: () => import('@/components/HomePage.vue')
    },
    {
      path: '/police-state',
      name: 'PoliceState',
      component: () => import('@/components/PoliceStatePage.vue')
    },
    {
      path: '/raymond',
      name: 'Raymond',
      component: () => import('@/components/RaymondPage.vue')
    },
    {
      path: '/big-news',
      name: 'BigNews',
      component: () => import('@/components/BigNewsPage.vue')
    },
    {
      path: '/hurricane',
      name: 'Hurricane',
      component: () => import('@/components/HurricanePage.vue')
    },
    {
      path: '/jeff',
      name: 'Jeff',
      component: () => import('@/components/JeffPage.vue')
    },
    {
      path: '/jessica',
      name: 'Jessica',
      component: () => import('@/components/JessicaPage.vue')
    },
    {
      path: '/mark',
      name: 'Mark',
      component: () => import('@/components/MarkPage.vue')
    },
    {
      path: '/frank',
      name: 'Frank',
      component: () => import('@/components/FrankPage.vue')
    },
    {
      path: '/breathing',
      name: 'Breathing',
      component: () => import('@/components/BreathingPage.vue')
    },
    {
      path: '/apocalypse',
      name: 'Apocalypse',
      component: () => import('@/components/ApocalypsePage.vue')
    },
    {
      path: '/time',
      name: 'Time',
      component: () => import('@/components/TimePage.vue')
    }
  ]
})
