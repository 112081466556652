<template>
  <header id="header">
    <div class="inner">
      <div class="content">
        <router-link :to="{ name: 'Home', params: {} }" class="button big alt"><span>shutterwax</span></router-link>
      </div>
      <a href="#" class="button hidden"><span>shutterwax</span></a>
    </div>
  </header>
</template>

<script>
export default {
  /* eslint-disable */
  name: 'Header',
  mounted() {
    // document.querySelectorAll('.button').forEach((b) => {
    //   let uri = b.getAttribute('href') === '#' ? '/' : '#'
    //   b.addEventListener('click', () => {
    //     b.setAttribute('href', uri)
    //   })
    // })
  }
}
</script>

<style scoped>
.inner {
  background: #fff url('/images/sw-logo.png') no-repeat center center !important;
  background-size: 100% !important;
}

#header {
  -moz-transition: background 1s ease-in-out, height 0.2s ease-in-out;
  -webkit-transition: background 1s ease-in-out, height 0.2s ease-in-out;
  -ms-transition: background 1s ease-in-out, height 0.2s ease-in-out;
  transition: background 1s ease-in-out, height 0.2s ease-in-out;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -ms-flexbox;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  background: rgba(254, 254, 254, 0.85);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10001;
}

#header .inner {
  -moz-transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  -webkit-transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  -ms-transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -ms-flexbox;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  background: #e74c3c;
  border-radius: 100%;
  width: 35em;
  height: 35em;
  padding: 4em;
  text-align: center;
  box-shadow: 0 0 0 1em #FFF;
  cursor: default;
}

#header .inner .content {
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

#header .inner .button {
  -moz-transition: background 1s ease-in-out;
  -webkit-transition: background 1s ease-in-out;
  -ms-transition: background 1s ease-in-out;
  transition: background 1s ease-in-out;
  margin: 0;
  border-radius: 30px;
}

#header .inner .button.hidden {
  display: none;
  cursor: pointer;
}

#header .inner.hidden {
  display: none;
}

#header.hide, #header.preview {
  -moz-transition: background 0.2s ease-in-out, height 1s ease-in-out;
  -webkit-transition: background 0.2s ease-in-out, height 1s ease-in-out;
  -ms-transition: background 0.2s ease-in-out, height 1s ease-in-out;
  transition: background 0.2s ease-in-out, height 1s ease-in-out;
  background: none;
  height: 0;
}

#header.hide .inner, #header.preview .inner {
  height: 6em;
  width: 6em;
  padding: 2em;
}

#header.hide .inner .content, #header.preview .inner .content {
  opacity: 0;
}

#header.hide .inner .content h1, #header.preview .inner .content h1 {
  display: none;
}

#header.hide .inner .content h2, #header.preview .inner .content h2 {
  display: none;
}

#header.hide .button.big, #header.preview .button.big {
  display: none;
}

#header.hide .button.hidden, #header.preview .button.hidden {
  text-decoration: none;
  padding-top: 1.5em;
  padding-left: 0;
  padding-right: 0;
  background: none;
  display: inline-block;
}

#header.hide .button.hidden:before, #header.preview .button.hidden:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

#header.hide .button.hidden:before, #header.preview .button.hidden:before {
  content: '\f015';
  color: #FFF;
  font-size: 1.5em;
}

#header.hide .button.hidden span, #header.preview .button.hidden span {
  display: none;
}

@media screen and (max-width: 736px) {

#header .inner {
  width: 25em;
  height: 25em;
  font-size: .75em;
  padding: 3em;
}

}

@media screen and (max-width: 480px) {

  #header .inner {
    width: 26em;
    height: 26em;
    padding: 2em;
    font-size: .65em;
  }

  #header .inner .button {
    padding-left: 2em;
    padding-right: 2em;
  }

}
</style>
